<template>

  <div class="sports_links">
<!--    <router-link  class="bw2 btn-bet-sports" tag="button" :to="{path: '/sbetinfo', query: {t: new Date().getTime()}}"> 스포츠</router-link>-->
<!--    <router-link class="bw2 btn-bet-mini" tag="button" :to="{path: '/leisure_betinfo', query: {t: new Date().getTime()}}">미니게임</router-link>-->
  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

  export default {
    name: "BetInfoLinksComp",
    props: {
      displayPosition: {
        type: Number,
        default() {
          return sportsConst.GAME_DISPLAYPOSITION_1x2;
        }
      },
      postion:{
        type: Number,
        default() {
          return 1;
        }
      }
    },
    data(){
      return {
        sportsConst,
        type:1
      }
    },
    methods:{

    },
  }
</script>

<style scoped>

</style>