<template>

    <div>
      <div class="llbc">
        <div class="del dth">

        </div>
        <div class="seq dth">회차</div>
        <div class="time dth">시간</div>
        <div class="type dth">종목</div>
        <div class="bet dth">베팅</div>
        <div class="amout dth">베팅금액</div>
        <div class="win dth">당첨금액</div>
        <div class="res dth">베팅결과</div>
      </div>
      <div class="llbc dtd" v-for="(item,index) in betList" :key="index">
        <div class="del" @click="deleteBet(item.id)">
          <i class="fa fa-close" style="font-size: 12px;color: red;cursor: pointer"></i>
        </div>
        <div class="seq">{{item.leisureGame.sequence}}</div>
        <div class="time">{{item.createTime|datef('DD일 HH:mm')}}</div>
        <div class="type">{{item.attributeConfig.attrName}}</div>
        <div class="bet bbg">
          <font  v-if="item.attribute === leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL">{{item.selectedValue}}</font>
          <font  v-if="item.attribute !== leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL"><p>{{item.selectedValueText}}</p></font>
          <font  v-if="item.leisureGame.homeTeamName != null" style="color: #fff9ff">
            {{item.leisureGame.homeTeamName + ' vs '+item.leisureGame.awayTeamName}}
          </font>

        </div>
        <div class="amout">{{item.betCash|comma}}</div>
        <div class="win">{{item.totalCash|comma}}</div>
        <div class="res">
          <font style="color: #fc4646;" v-if="item.betResult === sportsConst.BET_RESULT_LOSE"
                class="text-red">낙첨</font>
          <font v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text-orange">적특</font>
          <font v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text-red">취소</font>
          <font v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text-skyblue">당첨</font>
          <font v-if="item.betResult === sportsConst.BET_RESULT_WAITING">대기</font>
        </div>
      </div>
      <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 20px">
        <button class="badge badge-red" style="padding: 5px 15px;margin: 5px;border-radius: 3px" @click="deleteAllLeisureBetInfo">
          <i class="fa fa-close"> 전체삭제</i>
        </button>
      </div>


    </div>


</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import leisureConst from "../../common/leisureConst";
  import {deleteAllLeisureBet} from "../../network/leisureRequest";

  export default {
    name: "LeisureBetListComp",
    props: {
      betList: {
        type: Array,
        default() {
          return []
        }
      },
      position: {
        type: Number,
        default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
      }
    },
    data() {
      return {
        sportsConst,
        leisureConst,
        betIds: [],
      }
    },
    methods: {
      deleteAllLeisureBetInfo() {
        this.$swal({
          title: '전체 베팅내역을 삭제하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          if (res.value) {
            deleteAllLeisureBet().then(res => {
              if (res.data.success) {
                this.$emit('cancelOrDelBet')
              } else {
                this.$swal({
                  title: res.data.msg,
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true
                })
              }
            })
          }
        })
      },
      checkBetId(id) {
        let index = this.betIds.findIndex((item) => {
          return item === id
        });
        if (index === -1) {
          this.betIds.push(id)
        } else {
          this.betIds.splice(index, 1)
        }
        this.$emit('betInfoChecked', this.betIds)
      },
      deleteBet(id) {
        this.$swal({
          title: '해당 베팅내역을 삭제하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            this.$emit('deleteBet', id)
          }
        })
      },
      cancelBet(id) {
        this.$swal({
          title: '해당 베팅내역을 취소하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            this.$emit('cancelBet', id)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .llbc {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #fff9ff;
    border-bottom: 1px solid #4a4a4b;
  }

  .llbc div {
    text-align: center;
  }

  .dth {
    background-color: #030201;
    line-height: 28px;
  }
  .dtd{
    background-color: #272932;
    line-height: 28px;
  }

  .bbg {
    color: gold;
  }
  .del{
    width: 5%;
  }
  .seq {
    width: 9%;
    text-align: center;
  }

  .time {
    width: 14.2%;
  }

  .type {
    width: 23.2%;
  }

  .bet {
    width: 14.2%;
  }

  .amout {
    width: 14.2%;
  }

  .win {
    width: 14.2%;
  }

  .res {
    width: 14.2%;
  }

  @media screen and (max-width: 1024px) {
    .llbc div {
      font-size: 10px;
    }
    .seq {
      width: 12%;
      text-align: center;

    }

    .type {
      width: 23.3%;   color: #b3b1b3!important;

    }
    .res {
      width: 11%;
    }

  }



</style>